import React, { useState } from 'react';
import BingTracking from 'react-tracking-bing';
import {
  Box,
  Grid,
  VStack,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  ModalFooter,
  Badge,
  Divider,
  GridItem,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  FaEnvelope,
  FaHome,
  FaCity,
  FaGlobe,
  FaMailBulk,
  FaQuestionCircle,
} from 'react-icons/fa';
import AddressInformationModal from './WhyWeNeedThisInfoModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const EntitlementPage = () => {
  BingTracking.initialize('149006196');
  window.gtag('config', 'G-83KZEHBVC0');
  window.gtag('config', 'UA-224099637-1');
  BingTracking.trackPageview();
  BingTracking.trackEvent('submitClaimView', '', 'submitClaimView', '0');
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeError, setPostalCodeError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  const flight = location?.state?.flight;
  const navigate = useNavigate();

  // Dummy function to simulate entitlement calculation
  const calculateEntitlement = () => '£' + flight?.debt;

  function handleSubmit(e) {
    // Validate form data
    if (email === '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (address === '') {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    if (city === '') {
      setCityError(true);
    } else {
      setCityError(false);
    }
    if (country === '') {
      setCountryError(true);
    } else {
      setCountryError(false);
    }
    if (postalCode === '') {
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }
    if (
      email === '' ||
      address === '' ||
      city === '' ||
      country === '' ||
      postalCode === ''
    ) {
      return;
    }

    e.preventDefault();
    let person = {
      email: email,
      address: address,
      city: city,
      country: country,
      postalCode: postalCode,
    };
    navigate('/pnr', { state: { person: person, flight: flight } });
  }
  return (
    <VStack p={8} spacing={6} align='stretch' maxW='container.xl' mx='auto'>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={10}
      >
        <Box
          p={6}
          borderRadius='lg'
          borderWidth='1px'
          borderColor='gray.300'
          bg={'white'}
          shadow='md'
        >
          <VStack align='start'>
            <Badge colorScheme='green' p={1} fontSize='0.8em'>
              Congratulations!
            </Badge>
            <Heading size='lg' mb={3}>
              Estimated Payout:{' '}
              <Text as='span' color='green.500'>
                {calculateEntitlement()}
              </Text>
            </Heading>
            {flight?.debt < 1 && (
              <Text mb={3} color='yellow.500'>
                <b>
                  We did not detect any debt for this flight. If you believe you
                  are entitled to compensation, please continue to submit your
                  claim.
                </b>
              </Text>
            )}
            <Text mb={4}>
              You're on your way to receiving benefits. Complete the form to
              finalize your entitlements.
            </Text>
            <Text fontSize='sm' color='gray.500'>
              Disclaimer: This is just an estimate and actual payout may vary.
            </Text>
          </VStack>
          <Divider my={6} />
          <AddressInformationModal />
        </Box>

        <VStack
          p={6}
          borderRadius='lg'
          borderWidth='1px'
          borderColor='gray.300'
          bg={'white'}
          spacing={4}
          shadow='md'
        >
          <Grid templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem colSpan={2}>
              <FormControl isRequired isInvalid={emailError}>
                <FormLabel>Email Address</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<Icon as={FaEnvelope} />}
                  />
                  <Input
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <FormErrorMessage>
                  Please enter a valid email address.
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <FormControl isRequired isInvalid={addressError}>
              <FormLabel>Address</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Icon as={FaHome} />}
                />
                <Input
                  type='text'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>Please enter a valid address.</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={cityError}>
              <FormLabel>City</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Icon as={FaCity} />}
                />
                <Input
                  type='text'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>Please enter a valid city.</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={countryError}>
              <FormLabel>Country</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Icon as={FaGlobe} />}
                />
                <Input
                  type='text'
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>Please enter a valid country.</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={postalCodeError}>
              <FormLabel>Postal Code</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Icon as={FaMailBulk} />}
                />
                <Input
                  type='text'
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>
                Please enter a valid postal code.
              </FormErrorMessage>
            </FormControl>
          </Grid>
          <Button
            colorScheme='blue'
            size='lg'
            px={10}
            boxShadow='md'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </VStack>
      </Grid>
    </VStack>
  );
};

export default EntitlementPage;
