import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import { FaTicketAlt, FaEnvelopeOpenText, FaSearch, FaQuestionCircle } from 'react-icons/fa';
const FindPnrModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline" size="md">
        <Icon as={FaSearch} mr={2} />
        Can't find your PNR?
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Locating Your PNR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
              <Icon as={FaQuestionCircle} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                PNR or Passenger Name Record is also known as "Record Locator", "Confirmation Number" and "Booking Reference."
              </Text>
            </Box>
            <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px" mt={4}>
              <Icon as={FaTicketAlt} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                Check your flight ticket for the PNR. It's usually located near the top of the ticket.
              </Text>
            </Box>
            <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px" mt={4}>
              <Icon as={FaEnvelopeOpenText} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                If you booked online, look for the PNR in your confirmation email. It's often found in the flight details section.
              </Text>
            </Box>
            <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px" mt={4}>
              <Icon as={FaSearch} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                Still can't find it? Send us a message at help@airjust.com and we'll help you locate your PNR. 
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FindPnrModal;
