import { useState, useEffect } from "react";
import { Box, Flex, Text, Circle, VStack, HStack, useBreakpointValue } from "@chakra-ui/react";
import { FaCheckCircle, FaPlane, FaFileAlt, FaUserAlt } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import { useLocation } from "react-router-dom";

const Steps = () => {
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState(0);
    useEffect(() => {
        const step = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
        
        if (step === "") {
            setCurrentStep(0);
        }
        else if (step === "flightselection") {
            setCurrentStep(1);
        }
        else if (step === "entitlement") {
            setCurrentStep(2);
        }
        else if (step === "pnr") {
            setCurrentStep(3);
        }
        else if (step === "confirmation") {
            setCurrentStep(4);
        }
        else {
            setCurrentStep(0);
        }
    
    }, [location.pathname]);
    
    const isMobile = useBreakpointValue({ base: true, md: false });

    const Step = ({ icon, label, stepNumber }) => {
        const isCompleted = stepNumber <= currentStep;
        return (
            <VStack align="center" spacing={0} display={isMobile && stepNumber !== currentStep ? "none" : "flex"}>
                <Circle size="35px" bg={isCompleted ? "green.500" : "gray.200"} color="white">
                    {isCompleted ? <FaCheckCircle size="20px" /> : icon}
                </Circle>
                <Text fontSize="sm" color={isCompleted ? "green.500" : "gray.500"} mt={2}>
                    {label}
                </Text>
            </VStack>
        );
    };

    const Connector = () => (
        <Box flex={1} height="2px" bg="gray.200" display={isMobile ? "none" : "block"} width="30px" />
    );

    return (
        <Flex justify="center" align="center" mt={5}>
            <HStack spacing={3}>
                <Step 
                    icon={<FaPlane size="20px" />} 
                    label="Itinerary" 
                    stepNumber={1} 
                />
                <Connector />
                <Step 
                    icon={<IoMdPricetag size="20px" />} 
                    label="Entitlement" 
                    stepNumber={2} 
                />
                <Connector />
                <Step 
                    icon={<FaUserAlt size="20px" />} 
                    label="Details" 
                    stepNumber={3} 
                />
                <Connector />
                <Step 
                    icon={<FaFileAlt size="20px" />} 
                    label="Confirmation" 
                    stepNumber={4} 
                />
            </HStack>
        </Flex>
    );
};

export default Steps;