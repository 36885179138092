import React, { useState } from 'react';
import BingTracking from 'react-tracking-bing';
import {
  Box,
  Grid,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Image,
  Text,
  useToast,
  Divider,
  InputGroup,
  GridItem,
  useColorModeValue,
  Icon,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  FaEnvelope,
  FaMedal,
  FaUser,
  FaPlane,
  FaCheckSquare,
  FaQuestionCircle,
  FaRegSadTear,
} from 'react-icons/fa';
import MultiplePeopleModal from './MultiplePeopleModal';
import FindPnrModal from './FindPnrModal';
import { Form, useLocation, useNavigate } from 'react-router-dom';
import ticketImage from './airjustTicket.jpg';
import TermsModal from './TermsModal';
import SubmitClaim from './SubmitClaim';
import ReactPixel from 'react-facebook-pixel';

const ticketImageJpg = ticketImage;

const PnrEntryPage = () => {
  BingTracking.initialize('149006196');
  window.gtag('config', 'G-83KZEHBVC0');
  window.gtag('config', 'UA-224099637-1');
  BingTracking.trackPageview();
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [prn, setPrn] = useState('');
  const [prnError, setPrnError] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const toast = useToast();
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  const location = useLocation();
  const flight = location?.state?.flight;
  const person = location?.state?.person;
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Validate form data
    if (firstName === '') {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (lastName === '') {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (prn === '') {
      setPrnError(true);
    } else {
      setPrnError(false);
    }
    if (firstName === '' || lastName === '' || prn === '') {
      return;
    }
    if (!agreed) {
      toast({
        title: 'Agreement Required',
        description: 'Please agree to the terms and conditions.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    person.firstName = firstName;
    person.lastName = lastName;
    person.pnr = prn;
    SubmitClaim(person, flight);
    BingTracking.trackEvent('SubmitTicket', '', 'SubmitTicket', '0');
    ReactPixel.track('CompleteRegistration', {
      em: person.email,
      fn: person.firstName,
      ln: person.lastName,
    });

    window.gtag('event', 'submit_claim_new', {
      event_category: 'Conversion',
      event_label: 'Claim Submission',
    });
    navigate('/confirmation', { state: { person: person, flight: flight } });
  };

  return (
    <Box p={8} maxW='container.xl' mx='auto'>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={10}
      >
        <VStack
          align='start'
          spacing={4}
          p={6}
          shadow='md'
          borderRadius='xl'
          bg='white'
          borderWidth='1px'
          borderColor={borderColor}
        >
          <Heading size='lg' mb={2} color='blue.600'>
            <Icon as={FaMedal} mr={2} />
            Last Step!
          </Heading>
          <Text>
            Find your Confirmation number/PNR (Passenger Name Record) on your
            ticket or confirmation email. It’s your unique booking identifier.
          </Text>
          <Image src={ticketImageJpg} alt='Airjust Ticket' />
          <Divider />
          <FindPnrModal />
        </VStack>

        <VStack
          spacing={4}
          align='stretch'
          p={6}
          shadow='md'
          borderRadius='xl'
          bg='white'
          borderWidth='1px'
          borderColor={borderColor}
        >
          <FormControl isRequired isInvalid={firstNameError}>
            <FormLabel>
              <Icon as={FaUser} mr={2} />
              First Name
            </FormLabel>
            <Input
              type='text'
              placeholder='First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormErrorMessage>
              Please enter a valid first name.
            </FormErrorMessage>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              <Icon as={FaUser} mr={2} />
              Last Name
            </FormLabel>
            <Input
              type='text'
              placeholder='Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormErrorMessage>Please enter a valid last name.</FormErrorMessage>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              <Icon as={FaPlane} mr={2} />
              Identification Number / PNR
            </FormLabel>
            <Input
              type='text'
              placeholder='e.g. AJ1OST'
              value={prn}
              onChange={(e) => setPrn(e.target.value)}
            />
            <FormErrorMessage>Please enter a valid PNR.</FormErrorMessage>
          </FormControl>
          <Checkbox
            isChecked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            mt={2}
          >
            I agree to the <TermsModal /> and to be represented by Airjust
          </Checkbox>
          <Button
            colorScheme='blue'
            mt={4}
            size='lg'
            px={10}
            boxShadow='md'
            onClick={handleSubmit}
          >
            Submit Claim
          </Button>
          <MultiplePeopleModal />
        </VStack>
      </Grid>
    </Box>
  );
};

export default PnrEntryPage;
