import React, { useState, useEffect } from 'react';
import BingTracking from 'react-tracking-bing';
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Flex,
  Divider,
  useColorModeValue,
  Icon,
  Spacer,
} from '@chakra-ui/react';
import {
  FaPlaneDeparture,
  FaPlaneArrival,
  FaTicketAlt,
  FaQuestionCircle,
  FaClock,
} from 'react-icons/fa';
import FlightSelectionHelpModal from './FlightSelectionHelpModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

function convertDateTimeToTime(dateTime) {
  // Extract the time part from the dateTime string
  const timePart = dateTime.split('T')[1];

  // If the dateTime includes the time zone, it will be after a '+' or '-' in the time part
  // Split by '+' or '-' and take the first part to get the time without the time zone
  const timeWithoutTimeZone = timePart.split(/[\+\-]/)[0];

  // Extract hours and minutes
  const [hours, minutes] = timeWithoutTimeZone.split(':');

  // Format to 2-digit hours and minutes (e.g., '13:00')
  return `${hours}:${minutes}`;
}

const FlightSelectionPage = () => {
  BingTracking.initialize('149006196');
  BingTracking.trackPageview();
  window.gtag('config', 'G-83KZEHBVC0');
  window.gtag('config', 'UA-224099637-1');
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();

  const location = useLocation();
  const flights = location?.state?.flights;
  const navigate = useNavigate();

  const [selectedFlight, setSelectedFlight] = useState(null);

  const ticketBg = useColorModeValue('gray.50', 'gray.700');
  const dottedBorderStyle = '1px dashed gray';

  function calculatePayout(flight) {
    let debt = 0;
    if (flight.delay > 0) {
      if (flight.delay >= 120 && flight.delay < 180) {
        debt = 250;
      } else if (flight.delay >= 180 && flight.delay < 240) {
        if (flight.distance_km < 1500) {
          debt = 250;
        } else if (flight.distance_km >= 1500 && flight.distance_km < 3500) {
          debt = 400;
        } else if (flight.distance_km >= 3500) {
          debt = 400;
        }
      } else if (flight.delay >= 240) {
        if (flight.distance_km < 1500) {
          debt = 250;
        } else if (flight.distance_km >= 1500 && flight.distance_km < 3500) {
          debt = 400;
        } else if (flight.distance_km >= 3500) {
          debt = 600;
        }
      }
    }

    if (flight.cancelled) {
      if (flight.distance_km < 1500) {
        debt = 250;
      } else if (flight.distance_km >= 1500 && flight.distance_km < 3500) {
        debt = 400;
      } else if (flight.distance_km >= 3500) {
        debt = 600;
      }
    }

    return debt;
  }

  function handleSelectFlight(flight) {
    flight.debt = calculatePayout(flight);
    navigate('/entitlement', { state: { flight } });
  }

  return (
    <>
      <Flex
        direction='column'
        align='center'
        p={8}
        maxW='container.xl'
        mx='auto'
      >
        <Heading mb={6} textAlign='center'>
          Select Your Flight
        </Heading>
        <Box alignSelf='start' mb={6}>
          <FlightSelectionHelpModal />
        </Box>
        <VStack spacing={10} w='full'>
          {flights?.map((flight) => (
            <Box
              key={flight.flnr}
              p={6}
              borderRadius='lg'
              borderWidth='1px'
              borderColor='gray.300'
              bg={'white'}
              shadow='md'
              position='relative'
              overflow='hidden'
            >
              <Flex justifyContent='space-between' alignItems='center'>
                <VStack align='start' spacing={3}>
                  <Text fontWeight='bold' fontSize='xl'>
                    {flight.flnr}
                  </Text>
                  <Text fontSize='lg' color='gray.600'>
                    {flight.airline_name}
                  </Text>
                </VStack>
                <Button
                  colorScheme='blue'
                  size='md'
                  onClick={() => handleSelectFlight(flight)}
                >
                  Select
                </Button>
              </Flex>
              <Divider borderColor={dottedBorderStyle} my={4} />
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justifyContent='space-between'
                alignItems='center'
              >
                <VStack align='start' p={4} spacing={2}>
                  <Flex alignItems='center'>
                    <Icon as={FaPlaneDeparture} color='gray.500' mr={2} />
                    <Heading size='sm'>Scheduled Departure</Heading>
                  </Flex>
                  <Flex alignItems='center'>
                    <Icon as={FaClock} color='gray.500' mr={2} />
                    <Text fontSize='md'>
                      {convertDateTimeToTime(flight.scheduled_departure_local)}{' '}
                      - {flight.departure_name}
                    </Text>
                  </Flex>
                </VStack>
                <VStack align='start' p={4} spacing={2}>
                  <Flex alignItems='center'>
                    <Icon as={FaPlaneDeparture} color='gray.500' mr={2} />
                    <Heading size='sm'>Actual Departure</Heading>
                  </Flex>
                  <Flex alignItems='center'>
                    <Icon as={FaClock} color='gray.500' mr={2} />
                    <Text fontSize='md'>
                      {flight.actual_departure_local
                        ? convertDateTimeToTime(flight.actual_departure_local)
                        : 'Canceled'}{' '}
                      - {flight.departure_name}
                    </Text>
                  </Flex>
                </VStack>
                <Spacer />
                <Divider
                  orientation='vertical'
                  borderColor={dottedBorderStyle}
                  height='80px'
                  display={{ base: 'none', md: 'block' }}
                />
                <VStack align='start' p={4} spacing={2}>
                  <Flex alignItems='center'>
                    <Icon as={FaPlaneArrival} color='gray.500' mr={2} />
                    <Heading size='sm'>Scheduled Arrival</Heading>
                  </Flex>
                  <Flex alignItems='center'>
                    <Icon as={FaClock} color='gray.500' mr={2} />
                    <Text fontSize='md'>
                      {flight.scheduled_arrival_local
                        ? convertDateTimeToTime(flight.scheduled_arrival_local)
                        : 'Canceled'}{' '}
                      - {flight.arrival_name}
                    </Text>
                  </Flex>
                </VStack>
                <VStack align='start' p={4} spacing={2}>
                  <Flex alignItems='center'>
                    <Icon as={FaPlaneArrival} color='gray.500' mr={2} />
                    <Heading size='sm'>Actual Arrival</Heading>
                  </Flex>
                  <Flex alignItems='center'>
                    <Icon as={FaClock} color='gray.500' mr={2} />
                    <Text fontSize='md'>
                      {flight.actual_arrival_local
                        ? convertDateTimeToTime(flight.actual_arrival_local)
                        : 'Canceled'}{' '}
                      - {flight.arrival_name}
                    </Text>
                  </Flex>
                </VStack>
              </Flex>
            </Box>
          ))}
        </VStack>
      </Flex>
    </>
  );
};

export default FlightSelectionPage;
