import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  Box,
  Text,
  Icon
} from '@chakra-ui/react';
import { FaPlaneDeparture, FaTicketAlt, FaEnvelope } from 'react-icons/fa';
import { useDisclosure } from '@chakra-ui/react';

const FlightSelectionHelpModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline" size="md">
        Unsure which to select?
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Can't Find Your Flight?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(1, 1fr)" gap={6}>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaPlaneDeparture} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">Check the time your flight took off and landed.</Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaTicketAlt} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">If still unsure, check your ticket or confirmation email for the ticket number.</Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaEnvelope} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">Email us at help@airjust.com with your flight details if you need further assistance.</Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Text mt={2} fontSize="md">Search your flight directly with our search tool</Text>
                <br />
                <Button colorScheme="blue" size="md" onClick={() => window.open("https://app.airjust.co")}>Search Flight</Button>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FlightSelectionHelpModal;
