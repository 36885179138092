import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Switch,
  Button,
  useMediaQuery,
  Icon,
  Stack,
  Input,
  Grid,
  GridItem,
  Divider,
  FormErrorMessage,
  Text,
  Toast,
  ToastBody,
  ToastHeader,
  useToast
} from '@chakra-ui/react';
import { FaPlaneDeparture, FaPlaneArrival, FaPlane, FaCalendarAlt } from "react-icons/fa";
import AirportAutocomplete from './AirportSearchInput';
import GetFlights from './GetFlights';
import { Form, useNavigate } from 'react-router-dom';
import AirlineSearchInput from './AirlineSearchInput';
import { Spinner } from '@chakra-ui/react';


const FlightBookingForm = ({ csvFilePath }) => {
  const [departureAirport, setDepartureAirport] = useState('');
  const [departureAirportError, setDepartureAirportError] = useState(false);
  const [arrivalAirport, setArrivalAirport] = useState('');
  const [arrivalAirportError, setArrivalAirportError] = useState(false);
  const [flightDate, setFlightDate] = useState('');
  const [flightDateError, setFlightDateError] = useState(false);
  const [airline, setAirline] = useState('');
  const [airlineError, setAirlineError] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  async function HandleSubmit() {
    setIsLoading(true);
    // Validate form data
    if (departureAirport === '') {
      setDepartureAirportError(true);
    }else{
      setDepartureAirportError(false);
    }
    if (arrivalAirport === '') {
      setArrivalAirportError(true);
    }else{
      setArrivalAirportError(false);
    }
    if (flightDate === '') {
      setFlightDateError(true);
    }else{
      setFlightDateError(false);
    }
    if (airline === '') {
      setAirlineError(true);
    }else{
      setAirlineError(false);
    }
    if (departureAirport === '' || arrivalAirport === '' || flightDate === '' || airline === '') {
      setIsLoading(false);
      return;
    }

    try {
      let flights = await GetFlights(departureAirport, arrivalAirport, flightDate, airline);
      if (flights) {
        if (flights.length !== 0) {
          navigate('/flightselection', { state: { flights } });
        }else{
          toast({
            title: "No flights found.",
            description: "There are no flights matching your search criteria.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        }
      } else {
        toast({
          title: "An error occurred.",
          description: "There was an error fetching flights. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }

    } catch (error) {
      console.error('Error fetching flights:', error);
      toast({
        title: "An error occurred.",
        description: "There was an error fetching flights. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
    setIsLoading(false);
  }
  

  return (
      <Box>
        <Heading fontSize="24px" mb="5" textAlign="center" color="#2D3748">
          Flight Itinerary
        </Heading>
        <Text fontSize="md" mb="8" textAlign="center" color="#4A5568">
        Enter your flight details to get a free payout estimate!
        </Text>
        <Box
        >
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
            <FormControl id="departure-airport" 
            isInvalid={departureAirportError}
            isRequired>
              <FormLabel>Departed from</FormLabel>
              <Flex alignItems="center" mt={1}>
                <Icon as={FaPlaneDeparture} color="#4A5568" mr={2} />
                <AirportAutocomplete
                  csvFilePath={csvFilePath}
                  onAirportSelect={(airport) => setDepartureAirport(airport)}
                />
              </Flex>
              <FormErrorMessage>Please select a valid airport.</FormErrorMessage>
            </FormControl>
            <FormControl id="arrival-airport" 
            isInvalid={arrivalAirportError}
            isRequired>
              <FormLabel>Arrived at</FormLabel>
              <Flex alignItems="center" mt={1}>
                <Icon as={FaPlaneArrival} color="#4A5568" mr={2} />
                <AirportAutocomplete
                  csvFilePath={csvFilePath}
                  onAirportSelect={(airport) => setArrivalAirport(airport)}
                />
              </Flex>
              <FormErrorMessage>Please select a valid airport.</FormErrorMessage>
            </FormControl>
            <FormControl id="airline" 
            isInvalid={airlineError}
            isRequired>
              <FormLabel>Airline</FormLabel>
              <Flex alignItems="center" mt={1}>
                <Icon as={FaPlane} color="#4A5568" mr={2} />
                <AirlineSearchInput
                  csvFilePath={csvFilePath}
                  onAirlineSelect={(airline) => setAirline(airline)}
                />
              </Flex>
              <FormErrorMessage>Please select a valid airline.</FormErrorMessage>
            </FormControl>   
            <FormControl id="flight-date" 
            isInvalid={flightDateError}
            isRequired>
            <FormLabel>Date of Flight</FormLabel>
            <Flex alignItems="center" mt={1}>
              <Icon as={FaCalendarAlt} color="#4A5568" mr={2} />
              <Input
                type="date"
                placeholder="Select Date"
                size="lg"
                rounded="md"
                borderColor="gray.200"
                _placeholder={{ color: 'gray.500' }}
                _hover={{ borderColor: 'gray.300' }}
                bg="white"
                _focus={{ borderColor: 'blue.500' }}
                w="full"
                value={flightDate}
                onChange={(e) => setFlightDate(e.target.value)}
              />
            </Flex>
            <FormErrorMessage>Please enter a valid date.</FormErrorMessage>
          </FormControl>  
          </Grid>
          <Divider my="5" />
          <Button
        w="full"
        size="lg"
        fontSize="md"
        rounded="md"
        bg="#3182CE"
        color="white"
        _hover={{ bg: '#63B3ED' }} 
        boxShadow="md"
        onClick={HandleSubmit}
        isLoading={isLoading}
        spinner={<Spinner size="sm" />}
      >
              Continue
            </Button>
        </Box>
      </Box>
  );
};

export default FlightBookingForm;
