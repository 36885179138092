import {
  Box,
  Flex,
  Image,
  Stack,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import WomanSittingImage from './womanSitting.png';
import FlightBookingForm from './FlightBookingForm';
import LearnMoreModal from './LearnMoreModal';
import BingTracking from 'react-tracking-bing';
import ReactPixel from 'react-facebook-pixel';

function FlightSearchPage() {
  BingTracking.initialize('149006196');
  BingTracking.trackPageview();
  BingTracking.trackEvent('verifyView', '', 'verifyView', '0');
  window.gtag('config', 'G-83KZEHBVC0');
  window.gtag('config', 'UA-224099637-1');
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();

  return (
    <Flex
      justifyContent='center'
      alignItems='stretch' // Align items to stretch to full height
      direction={{ base: 'column', md: 'row' }}
      spacing={6}
      pr={{ base: 4, md: 8, lg: 20, xl: 60 }}
      pl={{ base: 4, md: 8, lg: 20, xl: 60 }}
      pt={10}
      pb={10}
      gap={8}
    >
      <Box
        display={{ base: 'none', md: 'flex' }}
        p={10}
        borderRadius='lg'
        borderWidth='1px'
        borderColor='gray.300'
        bg={'white'}
        shadow='md'
        w={{ base: '30%', md: '30%', lg: '30%' }} // 30% width for medium and up
        flexGrow={1} // Grow to fill available space
      >
        <Stack spacing={4} align='center'>
          {' '}
          {/* Centered Stack */}
          <Heading as='h1' size='xl' textAlign='center'>
            Claim Your Flight
          </Heading>{' '}
          {/* Heading Style */}
          <Text fontSize='lg' textAlign='center'>
            {' '}
            {/* Text Style */}
            We are here to help you get your money back for your flight delay or
            cancellation.
          </Text>
          <Image
            src={WomanSittingImage}
            alt='Woman Sitting'
            boxSize='250px'
            objectFit='cover'
          />
          {/* More info button */}
          <LearnMoreModal />
        </Stack>
      </Box>
      <Box
        p={10}
        borderRadius='lg'
        borderWidth='1px'
        borderColor='gray.300'
        bg={'white'}
        shadow='md'
        w={{ base: 'full', md: '50%' }} // 50% width for medium and up
        flexGrow={1} // Grow to fill available space
      >
        <FlightBookingForm />
      </Box>
    </Flex>
  );
}

export default FlightSearchPage;
