import axios from "axios";

const url = "https://izlsbx4ded.execute-api.eu-west-2.amazonaws.com/Production/search";

async function GetFlights(departureICAO, arrivalICAO, date, airline) {
    const params = {
        method: "POST",
        url: url,
        headers: {
        "Content-Type": "application/json",
        },
        data: {
            departureICAO: departureICAO,
            arrivalICAO: arrivalICAO,
            date: date,
            airline: airline,
        },
    };
    try {
        var response = await axios(params);
    } catch (error) {
        return null;
    }
    return response.data.body;
    }
export default GetFlights;