import { Box, Flex, IconButton, Image, useColorModeValue } from "@chakra-ui/react";
import { FaQuestionCircle } from "react-icons/fa";
import Steps from "./Steps";
import logo from "./logo.svg";
import ClaimsProcessModal from "./ClaimsProcessModal";

function Header() {
    const bg = useColorModeValue("white", "gray.800"); // Adjusts background based on light/dark mode
    const color = useColorModeValue("gray.600", "white"); // Adjusts color based on light/dark mode

    return (
        <Box bg={bg} px={4} py={3}>
            <Flex alignItems="center" justifyContent="space-between">
                <Image src={logo} alt="logo" h="40px" />
                <Box flex={1} mx={4}>
                    <Flex justifyContent="center">
                        <Steps />
                    </Flex>
                </Box>
                <ClaimsProcessModal/>
            </Flex>
        </Box>
    );
}

export default Header;
