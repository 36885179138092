import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

const PrivacyPolicyModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <Text as="a" onClick={onOpen} color="blue.500" fontSize="sm">
        Privacy Policy
    </Text>
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Privacy Policy</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            <Text fontWeight="bold">1. Introduction</Text>
            <Text>
              This Privacy Policy explains how Airjust.com Ltd. collects, uses, and discloses information about you.
            </Text>

            <Text fontWeight="bold">2. Data Collection</Text>
            <Text>
              We collect information provided by you, such as email and address, and data about your usage of our services.
            </Text>

            <Text fontWeight="bold">3. Data Use</Text>
            <Text>
              The information we collect is used to provide and improve our services, and for communication related to your account.
            </Text>

            <Text fontWeight="bold">4. Data Sharing</Text>
            <Text>
              We do not share your personal data with third parties except as necessary to provide our services or as required by law.
            </Text>

            <Text fontWeight="bold">5. Your Rights</Text>
            <Text>
              You have the right to access, update, or delete the information we hold about you.
            </Text>

            <Text fontWeight="bold">6. Changes to This Policy</Text>
            <Text>
              We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.
            </Text>

            {/* Include additional sections as necessary */}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
