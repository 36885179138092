import React, { useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Box, ChakraProvider } from '@chakra-ui/react';
import FlightSearchPage from './FlightSearch/FlightSearchPage';
import FlightSelectionPage from './FlightSearch/FlightSelectionPage';
import EntitlementPage from './EntitlementPage/EntitlementPage';
import PnrEntryPage from './PersonalDetailsEntry/PnrEntryPage';
import ConfirmationPage from './ConfirmationPage/ConfirmationPage';
import Header from './Header/Header';
import Footer from './Footer/Footer';

function App() {

  return (
    <ChakraProvider>
      <Box h={"100vh"} w={"100vw"} bg={"gray.100"} overflowX={"hidden"} overflowY={"auto"} >
      <HashRouter basename="/">
      <Header />
        <Routes>
          <Route path="/" element={<FlightSearchPage />} />
          <Route path="/entitlement" element={<EntitlementPage />} />
          <Route path="/pnr" element={<PnrEntryPage />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/flightselection" element={<FlightSelectionPage />} />
          <Route path="*" element={<Navigate to="/" />} /> 
        </Routes>
        <Footer />
        </HashRouter>
      </Box>
    </ChakraProvider>
  );
}

export default App;
