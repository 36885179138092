import React, { useState, useEffect } from 'react';
import { Input, Box, Stack, Text } from '@chakra-ui/react';
import Papa from 'papaparse';
import airports from "./filtered_airports_with_iata.csv";
import Fuse from 'fuse.js';

const csvFilePath = airports;

const useAirportsData = (csvFilePath) => {
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    fetch(csvFilePath)
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          complete: (results) => {
            setAirports(results.data);
          },
        });
      });
  }, [csvFilePath]);

  return airports;
};

const AirportAutocomplete = ({ onAirportSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedAirport, setSelectedAirport] = useState(null); 
  const [suggestions, setSuggestions] = useState([]);
  const [openAirportDropdown, setOpenAirportDropdown] = useState(true);
  const airports = useAirportsData(csvFilePath);
  const fuse = new Fuse(airports, {
    keys: [{ name: 'name', weight: 0.7 }, { name: 'iata_code', weight: 0.3 }, { name: 'ident', weight: 0.3 }, { name: 'municipality', weight: 0.3 }],
    includeScore: true,
    shouldSort: true,
    threshold: 0.3,
    location: 3,
    minMatchCharLength: 4,
    includeMatches: true
  });

  useEffect(() => {
    if (inputValue.length >= 3 && openAirportDropdown) {
      const fuzzyResults = fuse.search(inputValue);
      setSuggestions(fuzzyResults);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, airports, selectedAirport, openAirportDropdown]);

  const highlightMatch = (fuseItem) => {
    // Assuming the item and its matches are in the structure: { item: {...}, matches: [...] }
    const { item, matches } = fuseItem;
  
    // Function to build highlighted text
    const buildHighlightedText = (text, matchIndices) => {
      let lastIndex = 0;
      const parts = matchIndices.reduce((acc, [start, end]) => {
        acc.push(text.substring(lastIndex, start));
        acc.push(<strong key={start}>{text.substring(start, end + 1)}</strong>);
        lastIndex = end + 1;
        return acc;
      }, []);
      parts.push(text.substring(lastIndex));
      return <>{parts}</>;
    };
  
    // Generating highlighted text for each field
    const highlightedName = matches.find(m => m.key === 'name') ? 
      buildHighlightedText(item.name, matches.find(m => m.key === 'name').indices) : item.name;
    const highlightedIataCode = matches.find(m => m.key === 'iata_code') ? 
      buildHighlightedText(item.iata_code, matches.find(m => m.key === 'iata_code').indices) : item.iata_code;
    const highlightedIdent = matches.find(m => m.key === 'ident') ? 
      buildHighlightedText(item.ident, matches.find(m => m.key === 'ident').indices) : item.ident;
    const highlightedMunicipality = matches.find(m => m.key === 'municipality') ? 
      buildHighlightedText(item.municipality, matches.find(m => m.key === 'municipality').indices) : item.municipality;
  
    return {
      name: highlightedName,
      iata_code: highlightedIataCode,
      ident: highlightedIdent,
      municipality: highlightedMunicipality
    };
  };

  const handleSelect = (airport) => {
    onAirportSelect(airport.ident);
    setSelectedAirport(airport.name);
    setInputValue(airport.name);
    setOpenAirportDropdown(false);
    setSuggestions([]);
  };

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    setOpenAirportDropdown(true);
  }

  return (
    <Box w="full">
  <Input
    placeholder="Airport or City"
    value={inputValue}
    onChange={(e) => handleOnChange(e)}
    size="lg"
    rounded="md"
    borderColor="gray.200"
    _placeholder={{ color: 'gray.500' }}
    _hover={{ borderColor: 'gray.300' }}
    bg="white" 
    _focus={{ borderColor: 'blue.500' }}
    w="full"
  />


      {suggestions.length > 0 && inputValue.length >= 3 && openAirportDropdown && (
        <Box position="relative" mt="1">
          <Stack bg="white" position="absolute" width="100%" zIndex="1000" maxHeight="300px" overflowY="auto" shadow="md" rounded="md">
           { suggestions.map((fuseItem, index) => {
    const { name, iata_code, ident, municipality } = highlightMatch(fuseItem);

    return (
     <Box
                  key={index}
                  p={2}
                  borderBottom="1px"
                  borderColor="gray.100"
                  _hover={{ bg: "gray.50" }}
                  cursor="pointer"
                  onClick={() => handleSelect(fuseItem.item)}
                >
        <Text fontSize="md" fontWeight="normal">
          {name}
          <Text as="span" color="blue.500" fontWeight="normal">
            ({iata_code}/{ident})
          </Text>
        </Text>
        <Text as="div" color="gray.500" opacity="0.8" fontSize="sm" mt={1}>
          {municipality}, {fuseItem.item.iso_country}
        </Text>
      </Box>
    );
})}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default AirportAutocomplete;
