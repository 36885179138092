import React from 'react';
import { Box, Text, Link, Flex, useColorModeValue } from '@chakra-ui/react';
import PrivacyPolicyModal from './PrivacyPolicyModal';

function Footer() {
    const bg = useColorModeValue("gray.100", "gray.700");
    const color = useColorModeValue("gray.600", "white");

    return (
        // Keep footer at bottom of page
        <Box bg={bg} color={color} py={4} px={4} as="footer">
            <Flex flexDirection={{ base: 'column', sm: 'row' }} justifyContent="center" alignItems="center">
                <Text textAlign="center" mx={2}>
                    2023 © All Rights Reserved. Airjust Ltd.
                </Text>
                <PrivacyPolicyModal />
            </Flex>
        </Box>
    );
}

export default Footer;
