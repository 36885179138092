import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Icon,
  Divider
} from '@chakra-ui/react';
import { FaLock, FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';
import { useDisclosure } from '@chakra-ui/react';

const WhyWeNeedThisInfoModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <Button onClick={onOpen} leftIcon={<FaQuestionCircle />} colorScheme="blue" variant="outline" size="lg">
        Why we need this information
      </Button>
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Why We Need This Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Icon as={FaInfoCircle} color="blue.500" mr={2} />
            <Text fontSize="lg" fontWeight="semibold">Your Privacy Matters</Text>
            <Text>
              We require your email address to send you updates and your address details to process any entitlements.
            </Text>
          </Box>
          <Divider my={4} />
          <Box>
            <Icon as={FaLock} color="green.500" mr={2} />
            <Text fontSize="lg" fontWeight="semibold">Secure and Confidential</Text>
            <Text>Your information is kept secure and confidential at all times. We never sell your information and only use it to contact you and verify your identity.</Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>Got It</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};

export default WhyWeNeedThisInfoModal;