import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import { FaUsers, FaRegClipboard, FaLink } from 'react-icons/fa';

const MultiplePeopleModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline" size="md">
        <Icon as={FaUsers} mr={2} />
        Multiple people in your party?
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Claiming for Multiple People</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
              <Icon as={FaRegClipboard} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                If your party has multiple passengers, please submit a separate claim for each person using their unique PNR (Passenger Name Record).
              </Text>
            </Box>
            <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px" mt={4}>
              <Icon as={FaLink} w={6} h={6} color="blue.500" mb={2} />
              <Text fontSize="md">
                After submitting a claim, you'll find a link on the final page to start another claim for additional passengers.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Got It
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MultiplePeopleModal;
