import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  Box,
  Text,
  Icon
} from '@chakra-ui/react';
import { FaGavel, FaPoundSign, FaBuilding, FaHourglassHalf } from 'react-icons/fa';
import { useDisclosure } from '@chakra-ui/react';

const LearnMoreModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline" size="md">
        Learn More About Claims
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Understanding Your Rights</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaGavel} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Under EU Regulation 261/2004 and UK law, passengers can claim compensation for delayed or cancelled flights. This applies to flights departing from or arriving in the EU/UK.
                </Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaPoundSign} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Compensation can range from £250 to £600 depending on flight distance and delay duration. Rest assured, Airjust is here to help you claim what you’re owed.
                </Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaBuilding} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Airjust specializes in managing these claims. Our experienced team ensures a smooth process, handling everything on your behalf.
                </Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaHourglassHalf} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Claims typically process within 60 days. We aim for a quick resolution, keeping you informed every step of the way.
                </Text>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LearnMoreModal;
