import React from 'react';
import BingTracking from 'react-tracking-bing';
import {
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  Icon,
  Circle,
  Stack,
  useColorModeValue,
  Spacer,
  Button,
} from '@chakra-ui/react';
import {
  FaCheckCircle,
  FaBalanceScale,
  FaPaperPlane,
  FaMoneyCheckAlt,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const TimelineItem = ({ icon, title, description, color = 'blue.500' }) => {
  return (
    <VStack align='center' spacing={2}>
      <Circle size='40px' bg={color} color='white'>
        <Icon as={icon} />
      </Circle>
      <Box textAlign='center'>
        <Text fontWeight='bold'>{title}</Text>
        <Text fontSize='sm'>{description}</Text>
      </Box>
    </VStack>
  );
};

const FormCompletionPage = () => {
  BingTracking.initialize('149006196');
  window.gtag('config', 'G-83KZEHBVC0');
  window.gtag('config', 'UA-224099637-1');
  BingTracking.trackPageview();
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();

  const navigate = useNavigate();
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Box p={8} maxW='container.md' mx='auto'>
      <VStack spacing={6} align='stretch'>
        <Heading as='h1' size='lg'>
          Form Submitted Successfully!
        </Heading>
        <Text>
          Thank you for submitting your claim. If you have any questions, please
          reach out to us at{' '}
          <a href='mailto:help@airjust.com'>help@airjust.com</a>.
        </Text>
        <Heading as='h2' size='md' mb={2}>
          What Happens Next:
        </Heading>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify='center'
          align='center'
          mt={10}
        >
          <TimelineItem
            icon={FaCheckCircle}
            color='green.500'
            title='Claim Processing'
            description='We review your claim and gather additional information if needed.'
          />
          <Spacer />
          <Box
            bg={borderColor}
            height={{ base: '40px', md: '2px' }}
            width={{ base: '2px', md: '40px' }}
          />
          <Spacer />
          <TimelineItem
            icon={FaBalanceScale}
            title='Determine Action'
            description='Our team evaluates the best course of action for your claim.'
          />
          <Spacer />
          <Box
            bg={borderColor}
            height={{ base: '40px', md: '2px' }}
            width={{ base: '2px', md: '40px' }}
          />
          <Spacer />
          <TimelineItem
            icon={FaPaperPlane}
            title='Contacting Airline'
            description='Our law team sends a formal letter to the airline on your behalf.'
          />
          <Spacer />
          <Box
            bg={borderColor}
            height={{ base: '40px', md: '2px' }}
            width={{ base: '2px', md: '40px' }}
          />
          <Spacer />
          <TimelineItem
            icon={FaMoneyCheckAlt}
            title='Payment Processing'
            description='Once resolved, we process the payment and transfer it to your bank account.'
          />
        </Flex>
      </VStack>
      {/* Buttone to got back to first page to submit a new claim */}
      <Stack direction='row' justify='center' mt={10}>
        <Button
          variant='outline'
          colorScheme='blue'
          size='lg'
          onClick={() => navigate('/')}
        >
          Submit Another Claim
        </Button>
      </Stack>
    </Box>
  );
};

export default FormCompletionPage;
