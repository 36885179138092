import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

const TermsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <Text as="a" onClick={onOpen} color="blue.500" fontSize="sm">
        Terms and Conditions
    </Text>
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Terms and Conditions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            <Text fontWeight="bold">1. Introduction</Text>
            <Text>
              These Terms of Service govern your use of Airjust.com Ltd. services. By using our services, you agree to these terms.
            </Text>

            <Text fontWeight="bold">2. Service</Text>
            <Text>
                By submitting you agree to for us to file a compensation claim on your behalf and send payment to you. 
            </Text>

            <Text fontWeight="bold">3. Fees</Text>
            <Text>
                On the successful provision of Justice as a Service (meaning the receipt by the client of Flight Delay/Cancellation Compensation), AirJust will deduct its fee from the compensation.
                The service fee will amount 30%, including VAT of any compensation paid out by airlines pursuant to the claim.
            </Text>

            <Text fontWeight="bold">4. Additional Fees</Text>
            <Text>
            This fee will only be charged on the condition that the successful pursuit of a claim necessitated legal action, or action by our partners it will be deducted from flight compensation on top of the Service Fee.
            The Fee amounts to 15% of the Flight Compensation including VAT.
            </Text>

            <Text fontWeight="bold">5. Payment</Text>
            <Text>
            AirJust will not be liable for any additional fees/costs related to bank accounts of third-party intermediaries or the banks of the customer/client.
            </Text>

            <Text fontWeight="bold">6. Changes to This Policy</Text>
            <Text>
              We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.
            </Text>

            {/* Include additional sections as necessary */}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};

export default TermsModal;
