import axios from "axios";

const url = "https://izlsbx4ded.execute-api.eu-west-2.amazonaws.com/Production/submit";

async function SubmitClaim(person, flight) {
    const params = {
        method: "POST",
        url: url,
        headers: {
        "Content-Type": "application/json",
        },
        data: {
            person: person,
            flight: flight,
        },
    };
    try {
        var response = await axios(params);
    } catch (error) {
        return null;
    }
    return response.data.body;
    }
export default SubmitClaim;