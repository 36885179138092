import React, { useState, useEffect } from 'react';
import { Input, Box, Stack, Text, Icon } from '@chakra-ui/react';
import { FaPlaneDeparture, FaPlane, FaCheckCircle } from "react-icons/fa";
import Papa from 'papaparse';
import airlines from "./filtered_airlines_with_iata.csv";
import Fuse from 'fuse.js';

const csvFilePath = airlines;

const useAirlineData = (csvFilePath) => {
  const [airlines, setAirlines] = useState([]);

  useEffect(() => {
    fetch(csvFilePath)
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          complete: (results) => {
            setAirlines(results.data);
          },
        });
      });
  }, [csvFilePath]);

  return airlines;
};

const AirlineSearchInput = ({ onAirlineSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedAirline, setSelectedAirline] = useState(null); 
  const [suggestions, setSuggestions] = useState([]);
  const [openAirlineDropdown, setOpenAirlineDropdown] = useState(true);
  const airlines = useAirlineData(csvFilePath);
  const fuse = new Fuse(airlines, {
    keys: [{ name: 'name', weight: 0.7 }, { name: 'iata', weight: 0.3 }, { name: 'icao', weight: 0.3 }],
    includeScore: true,
    shouldSort: true,
    threshold: 0.3,
    location: 3,
    minMatchCharLength: 3,
    includeMatches: true
  });

  useEffect(() => {
    if (inputValue.length >= 2 && openAirlineDropdown) {
      const fuzzyResults = fuse.search(inputValue);
      setSuggestions(fuzzyResults);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, airlines, selectedAirline, openAirlineDropdown]);
  
  const highlightMatch = (fuseItem) => {
    // Assuming the item and its matches are in the structure: { item: {...}, matches: [...] }
    const { item, matches } = fuseItem;
  const buildHighlightedText = (text, matchIndices) => {
    let lastIndex = 0;
      const parts = matchIndices.reduce((acc, [start, end]) => {
        acc.push(text.substring(lastIndex, start));
        acc.push(<strong key={start}>{text.substring(start, end + 1)}</strong>);
        lastIndex = end + 1;
        return acc;
      }, []);
      parts.push(text.substring(lastIndex));
      return <>{parts}</>;
    };

    // Generating highlighted text for each field
    const highlightedName = matches.find(m => m.key === 'name') ? 
      buildHighlightedText(item.name, matches.find(m => m.key === 'name').indices) : item.name;
    const highlightedIata = matches.find(m => m.key === 'iata') ?
      buildHighlightedText(item.iata, matches.find(m => m.key === 'iata').indices) : item.iata;
    const highlightedIcao = matches.find(m => m.key === 'icao') ?
      buildHighlightedText(item.icao, matches.find(m => m.key === 'icao').indices) : item.icao;

      return {
        name: highlightedName,
        iata: highlightedIata,
        icao: highlightedIcao
      };
    }; 






  const handleSelect = (airline) => {
    onAirlineSelect(airline.iata);
    setSelectedAirline(airline.name);
    setOpenAirlineDropdown(false);
    setInputValue(airline.name);
    setSuggestions([]);
  };

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    setOpenAirlineDropdown(true);
  }

  return (
    <Box w="full">
  <Input
    placeholder="e.g. British Airlines"
    value={inputValue}
    onChange={(e) => handleOnChange(e)}
    size="lg"
    rounded="md"
    borderColor="gray.200"
    _placeholder={{ color: 'gray.500' }}
    _hover={{ borderColor: 'gray.300' }}
    bg="white" 
    _focus={{ borderColor: 'blue.500' }}
    w="full"
  />


      {suggestions.length > 0 && inputValue.length >= 2 && openAirlineDropdown && (
        <Box position="relative" mt="1">
          <Stack bg="white" position="absolute" width="100%" zIndex="1000" maxHeight="300px" overflowY="auto" shadow="md" rounded="md">
          { suggestions.map((fuseItem, index) => {
          const { name, iata, icao } = highlightMatch(fuseItem);

    return (
     <Box
                  key={index}
                  p={2}
                  borderBottom="1px"
                  borderColor="gray.100"
                  _hover={{ bg: "gray.50" }}
                  cursor="pointer"
                  onClick={() => handleSelect(fuseItem.item)}
                >
        <Text fontSize="md" fontWeight="normal">
          {name}
          <Text as="span" color="blue.500" fontWeight="normal">
            ({iata}/{icao})
          </Text>
        </Text>
      </Box>
    );
})}
              
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default AirlineSearchInput;
