import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  Box,
  Text,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import { FaHandshake, FaThumbsUp, FaPlaneArrival, FaQuestionCircle } from 'react-icons/fa';

const ClaimsProcessModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline" size="md">
        <Icon as={FaQuestionCircle} mr={2} />
        Help
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Easy Steps to Your Claim</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaPlaneArrival} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Delayed or cancelled flight? You might be eligible for compensation.
                </Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaHandshake} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Let Airjust handle the hard work. Complete our simple form, and we'll take it from there.
                </Text>
              </Box>
              <Box p={4} shadow="md" borderRadius="lg" borderWidth="1px">
                <Icon as={FaThumbsUp} w={6} h={6} color="blue.500" />
                <Text mt={2} fontSize="md">
                  Rest easy knowing our expert team is fighting for your rights.
                </Text>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClaimsProcessModal;
